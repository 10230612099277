<template>
  <v-dialog :value="value" persistent width="1200">
    <v-toolbar dark flat color="#1C3144">
      <v-btn icon @click="$emit('update:value', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{!selected.length ? `Edit - ${item.orderCode}` : `Edit ${selected.length} Feature Items`}}</v-toolbar-title>
    </v-toolbar>
    <v-container class="ma-0 pa-6">
      <v-row v-if="!isBulkEdit" dense>
        <v-col cols="2">
          <v-text-field readonly dense background-color="#fff" outlined label="Item Code"
            :value="item.orderCode"
            autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field readonly dense background-color="#fff" outlined label="Pack" :value="item.pack"
            autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field readonly dense background-color="#fff" outlined label="Size" :value="item.size"
            autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field readonly dense background-color="#fff" outlined label="UPC" :value="item.UPC"
            autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="5" class="mr-0">
          <v-text-field readonly dense background-color="#fff" outlined label="Item Description"
            :value="item.description" autocomplete="off">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" type="number"
            step="0.01" prefix="$" outlined label="Cost" v-model="item.cost" autocomplete="off" @change="formatCurrencyInput('cost', item.cost)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field max-width="100" dense background-color="#fff" placeholder="0.00" min="0"
            type="number" step="0.01" prefix="$" outlined label="AMAP" v-model="item.AMAP" autocomplete="off"
            @change="formatCurrencyInput('AMAP', item.AMAP)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" type="number"
            step="0.01" prefix="$" outlined label="EBA" v-model="item.EBA" autocomplete="off" @change="formatCurrencyInput('EBA', item.EBA)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" type="number"
            step="0.01" prefix="$" outlined label="Ad BB" v-model="item.adBB" autocomplete="off" @change="formatCurrencyInput('adBB', item.adBB)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" type="number"
            step="0.01" prefix="$" outlined label="Ad Scan" v-model="item.adScan" autocomplete="off"
            @change="formatCurrencyInput('adScan', item.adScan)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" type="number"
            step="0.01" prefix="$" outlined label="ePay" v-model="item.ePay" autocomplete="off" @change="formatCurrencyInput('ePay', item.ePay)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" max="1" type="number"
            step="0.01" prefix="$" outlined label="Compete" v-model="item.compete" autocomplete="off"
            @change="formatCurrencyInput('compete', item.compete)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" placeholder="0.00" min="0" type="number"
            step="0.01" prefix="$" outlined label="PCAF" v-model="item.PCAF" autocomplete="off" @change="formatCurrencyInput('PCAF', item.PCAF)">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field dense background-color="#fff" outlined label="Identifier" placeholder="ADVRD1"
            v-model="item.identifier" autocomplete="off">
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-divider/>
    <v-toolbar flat color="#fafafa">
      <v-spacer />
      <v-btn v-if="!isBulkEdit" height="40" color="#D32F2F" class="white--text mx-2" @click="confirmDialog=true">
        Delete<v-icon right>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-btn height="40" color="#00B24A" class="white--text" @click="isBulkEdit ? bulkUpdateFeatureItem() : updateFeatureItem()">
        Update
        <v-icon right>mdi-update</v-icon>
      </v-btn>
    </v-toolbar>

    <ConfirmDialog :remove="remove" :action="'Delete'" :type="'Feature Item'" :value.sync="confirmDialog" />

  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'FeatureItemEdit',

  components: {
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  mixins: [notification],

  props: [
    'value',
    'selected',
    'selectedFeature',
    'selectedFeatures',
    'selectedItem',
    'isBulkEdit',
    'getFeatureItems',
    'viewAllItems'
  ],

  data () {
    return {
      confirmDialog: false
    }
  },

  computed: {
    item () {
      return cloneDeep(this.selectedItem)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value && !this.isBulkEdit) {
          for (const key in this.item.item) {
            this.item[key] = this.item.item[key]
          }

          const valuesToFormat = {
            AMAP: this.item.AMAP,
            adBB: this.item.adBB,
            adScan: this.item.adScan,
            compete: this.item.compete,
            EBA: this.item.EBA,
            PCAF: this.item.PCAF,
            ePay: this.item.ePay,
            cost: this.item.cost
          }
          for (const key in valuesToFormat) {
            this.formatCurrencyInput(key, valuesToFormat[key])
          }
        }
      }
    }
  },

  methods: {
    formatCurrencyInput (key, value) {
      this.item[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    async bulkUpdateFeatureItem () {
      const promises = []
      const errors = []

      this.showLoader('Updating Items', true)

      this.selected.forEach(item => {
        for (const key in this.item) {
          if (!Object.prototype.hasOwnProperty.call(key, item) && key !== 'cost') {
            item[key] = this.item[key]
          } else {
            item.item.cost = this.item[key]
          }
        }

        promises.push(
          this.$ads.updateFeatureItem(item)
            .then().catch(() => {
              errors.push(`${item.item.orderCode} ${item.item.description} Not updated`)
            })
        )
      })

      await Promise.allSettled(promises)

      this.selectedFeatures.length ? this.viewAllItems() : this.getFeatureItems(this.selectedFeature)

      if (errors.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', 'Feature Items Successfully Updated')
      }
      this.$emit('update:value', false)
    },

    async updateFeatureItem () {
      this.item.item.cost = this.item.cost

      await this.$ads.updateFeatureItem(this.item)
        .then(() => {
          this.getFeatureItems(this.selectedFeature)
          this.notify('success', 'Feature Item Updated')
        }).catch(() => {
          this.notify('error', 'Feature Item Not Updated')
        })

      this.$emit('update:value', false)
    },

    async remove () {
      await this.$ads.deleteFeatureItem(this.item)
        .then(() => {
          this.notify('success', 'Item Successfully Deleted')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Item Not Deleted')
        })
      this.getFeatureItems(this.selectedFeature)
    }
  }
}
</script>
